import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-vancouver-washington.png'
import image0 from "../../images/cities/scale-model-of-the-wishing-tree-in-vancouver-washington.png"
import image1 from "../../images/cities/scale-model-of-couve-cycle-in-vancouver-washington.png"
import image2 from "../../images/cities/scale-model-of-peninsula-park-rose-garden-in-vancouver-washington.png"
import image3 from "../../images/cities/scale-model-of-the-grotto-in-vancouver-washington.png"
import image4 from "../../images/cities/scale-model-of-pearson-air-museum-in-vancouver-washington.png"
import image5 from "../../images/cities/scale-model-of-fort-vancouver-|-national-historic-site-in-vancouver-washington.png"
import image6 from "../../images/cities/scale-model-of-overlook-park-in-vancouver-washington.png"
import image7 from "../../images/cities/scale-model-of-vancouver-parks-&-recreation-in-vancouver-washington.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Vancouver'
            state='Washington'
            image={image}
            lat='45.6272259'
            lon='-122.6727305'
            attractions={ [{"name": "The Wishing Tree", "vicinity": "2954 NE 7th Ave, Portland", "types": ["point_of_interest", "establishment"], "lat": 45.5445213, "lng": -122.65848340000002}, {"name": "Couve Cycle", "vicinity": "202 W 5th St, Vancouver", "types": ["point_of_interest", "establishment"], "lat": 45.6251257, "lng": -122.6731939}, {"name": "Peninsula Park Rose Garden", "vicinity": "700 N Rosa Parks Way, Portland", "types": ["park", "point_of_interest", "establishment"], "lat": 45.5673726, "lng": -122.67338269999999}, {"name": "The Grotto", "vicinity": "8840 NE Skidmore St, Portland", "types": ["park", "church", "place_of_worship", "store", "point_of_interest", "establishment"], "lat": 45.5534899, "lng": -122.5754819}, {"name": "Pearson Air Museum", "vicinity": "1115 E 5th St, Vancouver", "types": ["museum", "point_of_interest", "establishment"], "lat": 45.6239412, "lng": -122.65627230000001}, {"name": "Fort Vancouver | National Historic Site", "vicinity": "612 E Reserve St, Vancouver", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 45.6257476, "lng": -122.6550957}, {"name": "Overlook Park", "vicinity": "1599 N Fremont St, Portland", "types": ["park", "point_of_interest", "establishment"], "lat": 45.54852229999999, "lng": -122.6825624}, {"name": "Vancouver Parks & Recreation", "vicinity": "610 Esther St, Vancouver", "types": ["point_of_interest", "establishment"], "lat": 45.6262563, "lng": -122.6766723}] }
            attractionImages={ {"The Wishing Tree":image0,"Couve Cycle":image1,"Peninsula Park Rose Garden":image2,"The Grotto":image3,"Pearson Air Museum":image4,"Fort Vancouver | National Historic Site":image5,"Overlook Park":image6,"Vancouver Parks & Recreation":image7,} }
       />);
  }
}